import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
        </p>
        <p className='footer-subscription-text'>
        </p>
        <div className='input-areas'>
          
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h3>Nosotros</h3>
            <Link to='/sign-up'>¿Quiénes somos?</Link>
            <Link to='/'>Feed Backs</Link>
            <Link to='/'>Clientes</Link>
            <Link to='/'>Términos de servicio</Link>
          </div>
          <div class='footer-link-items'>
            <h3>Contáctanos</h3>
            <Link to='/'>Contacto</Link>
            <Link to='/'>Soporte</Link>
            <Link to='/'>Unidades de Servicio</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h3>Media sociaux</h3>
            <Link to='https://www.instagram.com/kebrantabartenders/'target='_blank'>Instagram</Link>
            <Link to='https://www.facebook.com/kebrantacix'target='_blank'>Facebook</Link>
            <Link to='https://www.youtube.com/channel/UC-mMCwjaDNIwm_mEAAihfDw'target='_blank'>Youtube</Link>
            <Link to='https://www.tiktok.com/@kebrantabartenders?lang=es'target='_blank'>TikTok</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
            <img className='social-logo' src='/images/logokebranta/cabecera1.png'/>
            
            </Link>
          </div>
          <small class='website-rights'> © 2023</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='https://www.facebook.com/kebrantacix'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='https://www.instagram.com/kebrantabartenders/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='https://www.youtube.com/channel/UC-mMCwjaDNIwm_mEAAihfDw'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link tiktok'
              to='https://www.tiktok.com/@kebrantabartenders?lang=es'
              target='_blank'
              aria-label='tiktok'
            >
              <i class='fab fa-tiktok' />
            </Link>
            
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;