import React from 'react';
import CardItem from './CardItem';
import './Cards.css';
/*import FormInput from './FormInput';*/
import { Button } from './Button';


function Cards() {

  return (
    <div className='cards'>
      <h2>¿Quiénes Somos?</h2>
      <div className='general'>
        <p>Somos una empresa especializada en la creación de experiencias únicas de coctelería para eventos sociales. Nos especializamos en combinar sabores, creatividad y elegancia para hacer de cada celebración un momento inolvidable. Nuestro equipo de bartenders profesionales ofrece cocteles exclusivos y barras temáticas personalizadas, garantizando una atención de alta calidad y adaptada a los gustos de nuestros clientes para cualquier evento social. En Kebranta Bartenders estamos dedicados a transformar cada ocasión en una experiencia memorable.
</p>
      </div>
      
      <div className='rates'>
        <p className='chiffres'>Mas de 1600</p>
        <p className='categorie'>EVENTOS ATENDIDOS</p>
        <p className='chiffres'>Mas de 20,000</p>
        <p className='categorie'>PERSONAS</p>
        <p className='chiffres'> Mas de 52,000</p>
        <p className='categorie'>COCTELES</p>
        <p className='chiffres'>26</p>
        <p className='categorie'>CIUDADES</p>
      </div>
         <div className='cards__container'>
            <ul className='cards__items'>
                <CardItem 
                src='images/img-10.jpg'
                
                label='Servicio'
                path='/services' 
                />

                <CardItem 
                src='images/img-11.jpg'
                
                label='Cockteles'
                path='/products' 
                />
            </ul>
        
         </div>
      
      {/*<Button className='send'>envoyer</Button>*/}
    </div>
  )
}

export default Cards
