import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, SetButton] = useState(true)

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960) {
            SetButton(false);
        }else {
            SetButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('reize', showButton);



  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
            <Link to='/' className='navbar-logo'onClick={closeMobileMenu}>
                <img className='navbar-logo' src='/images/logokebranta/cabecera1.png'/>
            </Link>
            <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className='nav-item'>
                    <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                        Inicio
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
                        Servicios
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to='/products' className='nav-links' onClick={closeMobileMenu}>
                        Cocteles
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to='/Contact' className='nav-links' onClick={closeMobileMenu}>
                        Contacto
                    </Link>
                </li>
               
            </ul>
         
        </div>
      </nav> 
    </>
  );
}

export default Navbar;
