import {useRef}from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
    'service_r96bcak', 
    'template_fywr4um', form.current, 
    '3-SZwX4rpyr_DupM5')

      .then(
        (result) => {
          console.log(result.text);
      }, 
      (error) => {
        console.log(error.text);
      }
      );
      e.target.reset()
  };

  return (
    
   <section>
    <div className='container__formulaire'>
        <h2 className='--text-center'>Contacto</h2>
        <form ref={form} onSubmit={sendEmail}
         className='communication'>
            <input className='formulaire-input' type='text'
              placeholder='Nombre'
              name='user_name' required/>
            <input className='formulaire-input' type='email'
              placeholder='E-mail'
              name='user_email' required/>
            <input className='formulaire-input' type='Subject'
              placeholder='Subjeto'required/>
              
            <textarea className='formulaire-commentaires' type='message' placeholder='Enviar mensaje'></textarea>
            <button type='submit' className='btn--btn btn--outline btn--medium'>

                Enviar a     info@kebrantabartenders.com
                
            </button>
        </form>
    </div>
   </section>
   
  )
};



export default Contact;

