import React, { useEffect, useState } from "react";
import '../../App.css'
import'./Services.css'
import Footer from '../Footer';


const options = {
	// settings: {
	// 	overlayColor: 'rgb(25, 136, 124)',
	// 	autoplaySpeed: 1500,
	// 	transitionSpeed: 900
	// },
	// buttons: {
	// 	backgroundColor: 'red',
	// 	iconColor: 'rgba(126, 172, 139, 0.8)'
	// },
	// caption: {
	// 	captionColor: '#a6cfa5',
	// 	captionFontFamily: 'Raleway, sans-serif',
	// 	captionFontWeight: '300',
	// 	captionTextTransform: 'uppercase'
	// },
	// progressBar: {
	// 	height: '20px',
	// 	fillColor: 'blue',
	// 	backgroundColor: 'white'
	// }
};

const imagesModal = [
    { id: '1', imageName: 'bar1.jpg', tag: 'free' },
    { id: '2', imageName: 'bar2.jpeg', tag: 'pro' },
    { id: '3', imageName: 'bar3.jpg', tag: 'new' },
    { id: '4', imageName: 'bar4.jpeg', tag: 'new' },
    { id: '5', imageName: 'bar5.jpeg', tag: 'pro' },
    { id: '6', imageName: 'bar6.jpeg', tag: 'pro' },
    { id: '7', imageName: 'bar7.jpg', tag: 'pro' },
    { id: '8', imageName: 'bar8.jpg', tag: 'new' },
    { id: '9', imageName: 'bar9.jpeg', tag: 'free' },
    { id: '10', imageName: 'bar10.jpeg', tag: 'free' },
    { id: '11', imageName: 'bar11.jpg', tag: 'free' },
    { id: '12', imageName: 'bar12.jpeg', tag: 'free' },
    
  ];

function Services(){

    const[tag,setTag] = useState('all');
    const[ filteredImagesModal, setFilteredImagesModal] = useState([]);

    useEffect(
        ()=> {
          tag === 'all' ? setFilteredImagesModal(imagesModal) : setFilteredImagesModal(imagesModal.filter(image => image.tag === tag))
        }, 
        [tag]
    );

    return(
    <> 
    <h1 className="services">Servicio</h1>
    <div className="attendance">
        <p>Ofrecemos un menú diverso de exquisitos cocteles, siguiendo las últimas tendencias nacionales e internacionales de coctelería para eventos sociales. Cada coctel es cuidadosamente elaborado para brindar una experiencia sensorial única, asegurando que tu evento sea inolvidable.
        </p>
    </div>
    <div className="Modal">
			<div className="tags">
				<TagButton name="" tagActive={tag === 'all' ? true : false} handleSetTag={setTag} /> 
				<TagButton name="" tagActive={tag === 'new' ? true : false} handleSetTag={setTag} /> 
				<TagButton name="" tagActive={tag === 'free' ? true : false} handleSetTag={setTag} /> 
				<TagButton name="" tagActive={tag === 'pro' ? true : false} handleSetTag={setTag} />
			</div>
            
				<div className="containerservice">
					{filteredImagesModal.map(image => (
						<div key={image.id} className="imagecard">
							<a href={`/images/${image.imageName}`}>
								<img className="imageclass" src={`/images/${image.imageName}`} alt="" />
							</a>
						</div>
					))}
				</div>
            
			
    </div>
    
    <div className="tipoDeEventos">
        <div className="eventosSociales">
            <h2> Eventos Sociales</h2>
            <p>En Kebranta Bartenders, ofrecemos nuestros servicios para una amplia variedad de eventos. Atendemos aniversarios, fiestas de 15 años, bodas, cumpleaños, graduaciones y fiestas privadas, garantizando que cada celebración sea única y memorable.</p>
        </div>
        <div className="montageImageDerecha">
            <img src="/images/montage1.png"></img>
        </div>
    </div>
    <div className="destaque"></div>
    <div className="tipoDeEventos">
        <div className="montageImageIzquierda">
            <img src="/images/montage.png"></img>
        </div>
        <div className="eventosEmpresariales">
            <h2>Eventos Empresa</h2>
            <p>Los eventos empresariales son una especialidad de Kebranta Bartenders. Contamos con profesionales calificados que crearán cócteles excepcionales, brindando una experiencia inmersiva y única. Atendemos inauguraciones, lanzamientos, presentaciones, celebraciones y fiestas corporativas, asegurando que cada evento sea memorable y diferente a todo lo que haya probado antes.
            </p>
        </div>
        
    </div>
    <div className="brochure"> 
        <h2>Consulta nuestra brochure</h2>
        <iframe src="/images/BrochureKebrantaAbril2023.pdf" height="500" width="1200"></iframe>
        </div>
    <div className="brochure"> 
        <a href="/Users/jadevaldor/Documents/jadewebsites/react-website-jv-2 copy_Kebranta backup 24062024version finale_copy/public/images/BrochureKebrantaAbril2023.pdf" download={"BrochureKebrantaAbril2023.pdf"}> Click para descaragar nuestra brochure KebrantaBartenders </a>
        </div>
    <div className='btn-fixe'>
            {/*<a data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn-ouro" href="Contact"> + Pide una Soumision</a>
    */}
    </div>
    <Footer/>
    </>)

}
const TagButton = ({ name, handleSetTag, tagActive }) => {
	return (
		<button className={`tag ${tagActive ? 'active' : null}`} onClick={() => handleSetTag(name)}>
			{name.toUpperCase()}
		</button>
	);
}

export default Services;









