import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';


function HeroSection() {
  return (
    <div className='hero-container'>
        {<video src='/videos/video-5.mp4' autoPlay loop muted/>}
        <h1>¡Celebremos el regalo de vivir!</h1>
        <p>Haga que su celebración sea aún más especial</p>
          <div className='hero-btns'>
              <Button 
              className='btns'
              buttonStyle='btn--outline'
              buttonSize='btn--large'
              >
              Contáctanos
              </Button>
              <Button 
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'
              >
              Cotización<i className='far-fa-play-circle' />
              </Button>

          </div>
          {/* <div className='btn-fixe'> 
            <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn-ouro" href="Contact"> + Solicita Cotización</a>
          </div>*/}
    </div>
    
  )
}

export default HeroSection
