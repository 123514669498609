import React from "react";
import '../../App.css'
import Footer from '../Footer';

function Products(){
    return (
    <>
    <h1 className="products">Cocteles</h1>
    <div className="cocktail">
        <p>Un evento sensacional comienza con cócteles deliciosos. En Kebranta Bartenders, ofrecemos un menú diverso y cuidadosamente elaborado que no solo deleitará tu paladar, sino también tu vista. Cada cóctel es una obra de arte, visualmente impresionante y con sabores exquisitos que harán que tu celebración sea inolvidable. ¡Deja que nuestros cócteles sean el toque mágico que necesitas para un evento realmente inolvidable!
        </p>
    </div>
    {/*slider start */}
    <div className="recette">
        <h2>Cocteles más solicitados</h2>
        <div className="slideshow">
            <div className="slide">
                <div className="contentcocktel">
                    <h4>Machu Picchu</h4>
                    <strong>INGREDIENTES</strong>
                    <ul>Pisco, Licor de Menta, Jugo de Naranja, Granadina</ul>
                </div>
                <div className="kebrantaCocktel"><img src="/images/img-19.png" alt="mojito"/></div>
            </div>
            <div class="slide">
                <div className="contentcocktel">
                    <h4>Mojito</h4>
                    <strong>INGREDIENTES</strong>
                    <ul>Ron Blanco, Azúcar Líquida, Hierbabuena, Limón, Soda.</ul>
                </div>
                <div className="kebrantaCocktel"><img src="/images/img-20.png" alt="arcoiris"/></div>
            </div>
            <div class="slide">
                <div className="contentcocktel">
                    <h4>Algarobina</h4>
                    <strong>INGREDIENTES</strong>
                    <ul>Pisco, Licor de Cacao, Leche, Sirope de Azúcar, Vainilla, Canela.</ul>
                </div>
                <div className="kebrantaCocktel"><img src="/images/img-18.png" alt="chilcano"/></div>
            </div>
        </div>
    </div>
    {/*slider end */}
    <div className="brochure">
    </div>
    <div className='btn-fixe'>
            {/*<a data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn-ouro" href="Contact"> + Pide una Soumision</a>*/}
    </div>
    <Footer/>
    </>
    )
}
export default Products;